<template>
  <div class="w-100 text-center">
    <v-radio-group class="w-100 job-type-radios" v-model="view_model" row>
      <v-col cols="12" md="6">
        <v-radio value="open" dark color="#FF2FF2">
          <template v-slot:label>
            <div class="white--text w-100">
              <h2 class="font-weight-bold mb-3">Open Invitation</h2>
              <p class="font-weight-medium">
                This post is public and visible to all
              </p>
            </div>
          </template>
        </v-radio>
      </v-col>
      <v-col cols="12" md="6"
        ><v-radio value="closed" dark color="#FF2FF2">
          <template v-slot:label>
            <div class="white--text w-100">
              <h2 class="font-weight-bold mb-3">Closed Invitation</h2>
              <p class="font-weight-medium">
                This post can only be shared with talents you select
              </p>
            </div></template
          ></v-radio
        ></v-col
      >
    </v-radio-group>
    <div class="d-flex justify-center btn-holder">
      <v-btn class="btn1" :disabled="!value" @click="$emit('next')">
        Next
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'JobType',
    props: { value: { type: String } },
    mounted() {
      this.$scrollToTop();
    },
    computed: {
      view_model: {
        get() {
          // console.log(this.name, this.value)
          return this.value;
        },
        set(newVal) {
          // console.log(this.name, newVal)
          this.$emit('input', newVal);
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  ::v-deep .job-type-radios .v-input--selection-controls__input{
    align-self: start;
  }
</style>
