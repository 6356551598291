<template>
  <div class="w-100">
    <h2 class="font-weight-medium mb-8">Job Info</h2>
    <v-form ref="form" v-model="valid">
      <v-row>
        <template v-for="formElement in formConfig">
          <v-col :key="formElement.id" cols="12" md="6">
            <CustomInput
              v-model="formValues[formElement.id]"
              v-bind="formElement"
              dark
              outlined
            />
          </v-col> </template
      ></v-row>
      <div class="mt-10">
        <h4 class="font-weight-regular">
          Additional Tag <span class="grey--text">(Optional)</span>
        </h4>
      </div>
      <v-checkbox
        v-model="formValues.is_wfh"
        color="#FF2FF2"
        class="mb-n9 white--text"
        dark
        ><template v-slot:label
          ><span class="white--text">Work From Home</span></template
        ></v-checkbox
      >
      <v-checkbox v-model="formValues.is_paid" color="#FF2FF2" dark
        ><template v-slot:label
          ><span class="white--text">Paid Job</span></template
        ></v-checkbox
      >
    </v-form>
    <div class="d-flex justify-center btn-holder">
      <div class="text-center">
        <v-btn class="btn1" :disabled="!valid" @click="submitForm">
          Next
        </v-btn>
        <div
          class="underlineBtn mt-7"
          v-if="!$route.query.edit"
          @click="$emit('doItLater')"
        >
          Do it later
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CustomInput from '@/components/inputs/custom-input.vue';
  import { mapGetters } from 'vuex';
  export default {
    name: 'JobInfo1',
    props: ['formValues'],
    components: {
      CustomInput
    },
    computed: {
      ...mapGetters({
        project_type_options: 'constants/project_type_options',
        countries: 'constants/countries',
        allCountries: 'constants/allCountries'
      }),
      formConfig() {
        return [
          {
            id: 'project_type_id',
            name: 'project_type_id',
            placeholder: 'Job Type',
            rules: [(v) => !!v || 'Job Type is required'],
            label: 'Job Type',
            type: 'select',
            config: {
              items: this.project_type_options
            },
            required: true
          },
          {
            id: 'job_country_id[]',
            name: 'job_country_id[]',
            placeholder: 'Job Location',
            // rules: [(v) => v.length || 'Job Location is required'],
            rules: [],
            label: 'Job Location',
            type: 'select',
            config: {
              items: this.allCountries,
              multiple: true
            },
            required: true
          },
          {
            id: 'applicant_country_id[]',
            name: 'applicant_country_id[]',
            placeholder: 'Preferred Country of Applicant',
            // rules: [
            //   (v) => v.length || 'Preferred Country of Applicant is required'
            // ],
            rules: [],
            label: 'Preferred Country of Applicant',
            type: 'select',
            config: {
              items: this.allCountries,
              multiple: true
            },
            required: true
          },
          {
            id: 'close_date',
            name: 'close_date',
            placeholder: 'Application Closing Date',
            // rules: [(v) => !!v || 'Application Closing Date is required'],
            rules: [],
            label: 'Application Closing Date',
            type: 'date',
            required: true,
            config: {
              allowedDates: true,
              hideDetails: 'auto'
            }
          },
          {
            id: 'video_due',
            name: 'video_due',
            placeholder: 'Video Submission Date',
            // rules: [(v) => !!v || 'Video Submission Date is required'],
            rules: [],
            label: 'Video Submission Date',
            type: 'date',
            required: true,
            config: {
              allowedDates: true,
              hideDetails: 'auto'
            }
          },
          {
            id: 'live_date',
            name: 'live_date',
            placeholder: 'Job Posting Date',
            // rules: [(v) => !!v || 'Job Posting Date is required'],
            rules: [],
            label: 'Job Posting Date',
            type: 'date',
            required: true,
            config: {
              allowedDates: true,
              hideDetails: 'auto'
            }
          }
        ];
      }
    },
    mounted() {
      this.$scrollToTop();
    },
    methods: {
      submitForm() {
        if (this.$refs.form.validate()) this.$emit('next');
      }
    },
    data: () => {
      return { valid: false };
    }
  };
</script>
<style lang="scss">
  .w-50 {
    width: 50%;
  }
  .label-cont {
    padding: 80px 0 0 10px;
  }

  .grey--text {
    color: #848484;
  }
</style>
