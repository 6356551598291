<template>
  <div class="w-100">
    <v-form ref="form" v-model="valid" submit.preventDefault v-if="!isDataset4">
      <div v-if="projectType.datasetId == 3" class="mb-12">
        <v-row justify="space-between" no-gutters>
          <h2 class="font-weight-medium mb-3">Job Details</h2>
        </v-row>
        <v-row>
          <template v-for="formElement in jobDetailFormConfig">
            <v-col :key="formElement.id" v-bind="formElement.column">
              <CustomInput
                v-if="getHide(formValues, formElement.id)"
                v-model="formValues[formElement.id]"
                v-bind="formElement"
                dark
                outlined
                @clearFile="removeAsset"
              />
            </v-col>
          </template>
        </v-row>
      </div>

      <v-row justify="space-between">
        <h2 class="font-weight-medium ml-5 mb-3">Character Details</h2>
        <v-btn class="btn2 md font-weight-bold" @click="addCharacter">
          <v-icon class="icon">mdi-plus</v-icon> Add Character
        </v-btn>
      </v-row>
      <div class="d-flex mt-5 headerScroll">
        <template v-for="(item, i) in characters">
          <div
            :key="i"
            @click="currentTab = i"
            :class="'characterTab ' + (currentTab == i ? 'active' : '')"
          >
            {{ i + 1 }}
          </div>
        </template>
      </div>
      <v-row>
        <template v-for="formElement in formConfig">
          <v-col
            :key="formElement.id"
            v-bind="formElement.column"
            :class="formElement.id == 'gender' ? 'row' : ''"
          >
            <CustomInput
              v-if="getHide(characters[currentTab], formElement.id)"
              v-model="characters[currentTab][formElement.id]"
              v-bind="formElement"
              dark
              outlined
              @clearFile="removeAsset"
            />
            <CustomInput
              v-if="
                formElement.id == 'gender' &&
                characters[currentTab].gender == 'other'
              "
              v-model="characters[currentTab].gender_other"
              class="mt-auto ml-3"
              style="width: 300px"
              :label="$t('label.pleaseSpecify')"
              type="text"
              id="genderOther"
              name="genderOther"
              :rules="[(v) => !!v || 'This field is required']"
              :placeholder="$t('label.pleaseSpecify')"
              dark
            />
          </v-col>
        </template>
      </v-row>
      <v-btn
        v-if="characters.length > 1"
        block
        class="outlineBtn"
        @click="remove()"
      >
        <v-icon color="#FF2FF2" class="mr-4">mdi-minus</v-icon>
        Remove Character
      </v-btn>
    </v-form>
    <v-form ref="form" v-model="valid" submit.preventDefault v-if="isDataset4">
      <v-row justify="space-between">
        <h2 class="font-weight-medium ml-5 mb-3">Customise Details</h2>
        <v-btn
          class="btn2 md font-weight-bold"
          v-if="formValues.create_own_fields"
          @click="addCharacter"
        >
          <v-icon class="icon">mdi-plus</v-icon> Add Field
        </v-btn>
      </v-row>
      <div class="d-flex mt-5 headerScroll">
        <div v-for="(item, i) in formValues.create_own_fields" :key="i">
          <div
            @click="currentTab = i"
            :class="'characterTab ' + (currentTab == i ? 'active' : '')"
          >
            {{ i + 1 }}
          </div>
        </div>
      </div>
      <v-row>
        <template v-for="formElement in customiseDetailFormConfig">
          <v-col :key="formElement.id" v-bind="formElement.column">
            <CustomInput
              v-if="
                getHide(
                  formValues.create_own_fields[currentTab],
                  formElement.id
                )
              "
              v-model="formValues.create_own_fields[currentTab][formElement.id]"
              v-bind="formElement"
              dark
              outlined
            />
          </v-col>
        </template>
      </v-row>
      <v-btn
        v-if="formValues.create_own_fields"
        block
        class="outlineBtn"
        @click="remove()"
      >
        <v-icon color="#FF2FF2" class="mr-4">mdi-minus</v-icon>
        Remove Field
      </v-btn>
      <v-row class="mt-5">
        <template v-for="formElement in dataset4FormConfig">
          <v-col :key="formElement.id" v-bind="formElement.column">
            <CustomInput
              v-if="getHide(formValues, formElement.id)"
              v-model="formValues[formElement.id]"
              v-bind="formElement"
              dark
              outlined
              @clearFile="removeAsset"
            />
            <CustomInput
              v-if="formElement.id == 'gender' && formValues.gender == 'other'"
              v-model="formValues.gender_other"
              class="mt-auto ml-3"
              style="width: 300px"
              :label="$t('label.pleaseSpecify')"
              type="text"
              id="genderOther"
              name="genderOther"
              :rules="[(v) => !!v || 'This field is required']"
              :placeholder="$t('label.pleaseSpecify')"
              dark
            />
          </v-col>
        </template>
      </v-row>
    </v-form>
    <div class="text-center">
      <v-btn
        class="btn2 md font-weight-bold mt-5"
        @click="addCharacter"
        v-if="!isDataset4"
      >
        <v-icon class="icon">mdi-plus</v-icon> Add Character
      </v-btn>
    </div>
    <div class="d-flex justify-center btn-holder">
      <div class="text-center">
        <v-btn class="btn1" :disabled="!valid" @click="submitForm">
          {{ !$route.query.draft_id ? 'Save & Next' : 'Save' }}
        </v-btn>
        <div
          class="underlineBtn mt-7"
          v-if="!$route.query.edit"
          @click="$emit('doItLater')"
        >
          Do it later
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CustomInput from '@/components/inputs/custom-input.vue';
  import { mapGetters } from 'vuex';
  import { CREATE_JOB, PROFILE } from '@/api';

  export default {
    name: 'Character',
    props: ['formValues', 'projectType', 'characters'],
    components: {
      CustomInput
    },
    computed: {
      isDataset4() {
        return [4, '4'].includes(this.projectType.datasetId);
      },

      ...mapGetters({
        project_type_options: 'constants/project_type_options',
        countries: 'constants/countries',
        allCountries: 'constants/allCountries'
      }),
      jobDetailFormConfig() {
        return [
          {
            id: 'objective',
            name: 'objective',
            placeholder: 'Objectives',
            rules: [(v) => !!v || 'Objectives is required'],
            label: 'Objectives',
            type: 'textarea',
            multiline: true,
            rows: 6,
            column: { cols: '12', md: '12' },
            required: true
          },
          {
            id: 'target_audience',
            name: 'target_audience',
            placeholder: 'Target Audience',
            rules: [(v) => !!v || 'Target Audience is required'],
            label: 'Target Audience',
            type: 'textarea',
            multiline: true,
            rows: 6,
            column: { cols: '12', md: '12' },
            required: true
          },
          {
            id: 'casting_script_file',
            name: 'casting_script_file',
            placeholder: 'Casting Script (Optional - Max 10MB, PDF/Word Doc)',
            rules: [],
            label: 'Casting Script (Optional - Max 10MB, PDF/Word Doc)',
            type: 'file',
            config: {
              accept:
                '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf'
            },
            column: { cols: '12', md: '12' },
            required: false
          }
        ];
      },
      formConfig() {
        return [
          // {
          //   id: 'objective',
          //   name: 'objective',
          //   placeholder: 'Objective',
          //   rules: [],
          //   label: 'Objective',
          //   type: 'textarea',
          //   required: true,
          //   column: { cols: '12', md: '12' }
          // },
          // {
          //   id: 'target_audience',
          //   name: 'target_audience',
          //   placeholder: 'Target Audience',
          //   rules: [],
          //   label: 'Target Audience',
          //   type: 'textarea',
          //   required: true,
          //   column: { cols: '12', md: '12' }
          // },
          {
            id: 'casting_script_file',
            name: 'casting_script_file',
            placeholder: 'Casting Script (Optional - Max 10MB, PDF/Word Doc)',
            rules: [],
            label: 'Casting Script (Optional - Max 10MB, PDF/Word Doc)',
            type: 'file',
            config: {
              accept:
                '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf'
            },
            column: { cols: '12', md: '12' },
            required: false
          },
          {
            id: 'character',
            name: 'character',
            placeholder: 'Character / Role',
            rules: [(v) => !!v || 'Character / Role is required'],
            label: 'Character / Role',
            type: 'text',
            required: true,
            column: { cols: '12', md: '12' }
          },
          {
            id: 'project_role_id[]',
            name: 'project_role_id[]',
            placeholder: 'Project Role',
            rules: [(v) => !!v || 'Project Role is required'],
            label: 'Project Role',
            type: 'select',
            config: {
              items: this?.$store?.getters['constants/projectRoles'] || [],
              multiple: true
            },
            required: true,
            column: { cols: '12', md: '12' }
          },
          // {
          //   id: 'project_role_others',
          //   name: 'project_role_others',
          //   placeholder: 'Project Role Other',
          //   hide: true,
          //   rules: [],
          //   label: 'Project Role Other',
          //   type: 'text',
          //   column: { cols: '12', md: '12' }
          // },
          {
            id: 'character_write_up',
            name: 'character_write_up',
            placeholder: 'Character / Role Write Up ',
            rules: [(v) => !!v || 'Character / Role Write Up  is required'],
            label: 'Character / Role Write Up ',
            type: 'textarea',
            required: true,
            column: { cols: '12', md: '12' }
          },
          {
            id: 'age_range',
            name: 'age_range',
            placeholder: 'On-Screen Age',
            rules: [
              (v) => !!v || 'On-Screen Age is required',
              (v) =>
                !/[a-zA-Z]/g.test(v) ||
                `Character Details ${
                  this.currentTab + 1
                } On-Screen Age must be a number or '-'`
            ],
            label: 'On-Screen Age',
            type: 'text',
            column: { cols: '12', md: '6' },
            required: true
          },
          {
            id: 'opening_number',
            name: 'opening_number',
            placeholder: 'Number of Openings',
            rules: [(v) => !!v || 'Number of Openings  is required'],
            label: 'Number of Openings',
            type: 'number',
            column: { cols: '12', md: '6' },
            required: true
          },

          {
            id: 'gender',
            name: 'gender',
            placeholder: 'Sex',
            rules: [(v) => !!v || 'Sex is required'],
            label: 'Sex',
            type: 'radio',
            config: { items: this?.$store?.getters['constants/genders'] || [] },
            required: true,
            column: { cols: '12', md: '12' }
          },
          {
            id: 'skills',
            name: 'skills',
            placeholder: 'Role Skills (Optional)',
            rules: [],
            label: 'Role Skills (Optional)',
            type: 'tag-input',
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'age',
            name: 'age',
            placeholder: 'Age (Optional)',
            rules: [],
            label: 'Age (Optional)',
            type: 'select',
            config: {
              items: this?.$store?.getters['constants/age'] || []
            },
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'height',
            name: 'height',
            placeholder: 'Role Height (Optional)',
            rules: [],
            label: 'Role Height (Optional)',
            type: 'select',
            config: {
              items: this?.$store?.getters['constants/height'] || [],
              clearable: true
            },
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'location[]',
            name: 'location[]',
            placeholder: 'Role Location (Optional)',
            rules: [],
            label: 'Role Location (Optional)',
            type: 'select',
            config: {
              items:
                this?.$store?.getters['constants/allCountries'].filter(
                  (x) => x.abbreviation != 'WW'
                ) || [],
              multiple: true,
              clearable: true
            },
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'fees',
            name: 'fees',
            placeholder: 'Fees (Optional)',
            rules: [],
            config: {
              items: this?.$store?.getters['constants/currency'] || [],
              type: 'number'
            },
            label: 'Fees (Optional)',
            type: 'text-select',
            column: { cols: '12', md: '6' },
            min: 0,
            required: false
          },
          {
            id: 'race[]',
            name: 'race[]',
            placeholder: 'Race (Optional)',
            rules: [],
            label: 'Race (Optional)',
            type: 'select',
            config: {
              items: this?.$store?.getters['constants/race'] || [],
              multiple: true,
              clearable: true
            },
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'languages_spoken[]',
            name: 'languages_spoken[]',
            placeholder: 'Language (Optional)',
            rules: [],
            label: 'Language (Optional)',
            type: 'select',
            config: {
              items: this?.$store?.getters['constants/languageSpoken'] || [],
              multiple: true,
              clearable: true
            },
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'shooting_days',
            name: 'shooting_days',
            placeholder: 'No. of Shoot Days (Optional)',
            rules: [],
            label: 'No. of Shoot Days (Optional)',
            type: 'number',
            required: false
          }
        ];
      },
      customiseDetailFormConfig() {
        return [
          {
            id: 'name',
            name: 'name',
            placeholder: 'Title',
            rules: [(v) => !!v || 'Title is required'],
            label: 'Title',
            type: 'text',
            column: { cols: '12', md: '12' },
            required: true
          },
          {
            id: 'value',
            name: 'value',
            placeholder: 'Write Up',
            rules: [(v) => !!v || 'Write up is required'],
            label: 'Write Up',
            type: 'textarea',
            multiline: true,
            rows: 6,
            column: { cols: '12', md: '12' },
            required: true
          }
        ];
      },
      dataset4FormConfig() {
        return [
          {
            id: 'casting_script_file',
            name: 'casting_script_file',
            placeholder: 'Casting Script (Optional, PDF/Word Doc)',
            rules: [],
            label: 'Casting Script (Optional, PDF/Word Doc)',
            type: 'file',
            config: {
              accept:
                '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf'
            },
            column: { cols: '12', md: '12' },
            required: false
          },
          {
            id: 'gender',
            name: 'gender',
            placeholder: 'Sex',
            rules: [(v) => !!v || 'Sex is required'],
            label: 'Sex',
            type: 'radio',
            config: { items: this?.$store?.getters['constants/genders'] || [] },
            required: true,
            column: { cols: '12', md: '12' }
          },
          {
            id: 'on_screen_age',
            name: 'on_screen_age',
            placeholder: 'On-Screen Age (Optional)',
            rules: [
              (v) =>
                !/[a-zA-Z]/g.test(v) ||
                `Character Details ${
                  this.currentTab + 1
                } On-Screen Age must be a number or '-'`
            ],
            label: 'On-Screen Age (Optional)',
            type: 'text',
            column: { cols: '12', md: '6' },
            required: true
          },
          {
            id: 'age',
            name: 'age',
            placeholder: 'Age (Optional)',
            rules: [],
            label: 'Age (Optional)',
            type: 'select',
            config: {
              items: this?.$store?.getters['constants/age'] || []
            },
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'height',
            name: 'height',
            placeholder: 'Role Height (Optional)',
            rules: [],
            label: 'Role Height (Optional)',
            type: 'select',
            config: {
              items: this?.$store?.getters['constants/height'] || []
            },
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'skill',
            name: 'skill',
            placeholder: 'Role Skills (Optional)',
            rules: [],
            label: 'Role Skills (Optional)',
            type: 'tag-input',
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'fees',
            name: 'fees',
            placeholder: 'Fees (Optional)',
            rules: [],
            config: {
              items: this?.$store?.getters['constants/currency'] || [],
              type: 'text'
            },
            label: 'Fees (Optional)',
            type: 'text-select',
            column: { cols: '12', md: '6' },
            required: false
          },
          {
            id: 'opening_number',
            name: 'opening_number',
            placeholder: 'Number of Talents Required (Optional)',
            rules: [],
            label: 'Number of Talents Required (Optional)',
            type: 'number',
            column: { cols: '12', md: '6' },
            required: true
          }
        ];
      }
    },
    watch: {
      isDataset4() {
        this.formValues.create_own_fields = this.formValues
          .create_own_fields || [{}];
      }
    },
    methods: {
      submitForm() {
        // if (this.$refs.form.validate()) this.$emit('next');
        this.$emit('next');
      },
      getHide(currentData, fieldname) {
        if (fieldname != 'project_role_others') {
          return true;
        }
        return !!(currentData['project_role_id[]'] || []).find((i) => i == 16);
      },
      addCharacter() {
        this.$emit('addCharacter');
        this.$nextTick(() => {
          this.currentTab = this.characters.length - 1;
          this.$scrollToTop();
        });
      },
      async removeAsset(e) {
        if (e.id) {
          this.$startLoading();
          try {
            await PROFILE.removeFile(e.id);
          } catch (e) {
            console.error(e);
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        }
      },
      async remove() {
        const character = this.characters[this.currentTab];
        if (character?.id && this.projectType != 4) {
          this.$startLoading();
          try {
            this.$route?.query?.draft_id || character.casting_job_draft_id
              ? await CREATE_JOB.removeDraftRole(
                  this?.$route?.query?.draft_id ||
                    character.casting_job_draft_id,
                  character.id
                )
              : await CREATE_JOB.removeRole(
                  this?.$route?.query?.job_id,
                  character.id
                );
          } catch (e) {
            console.error(e);
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        }
        this.$emit('removeCharacter', this.currentTab);
        if (this.currentTab) {
          this.currentTab--;
        }
      }
    },
    mounted() {
      this.$scrollToTop();
      if (this.isDataset4) {
        this.formValues.create_own_fields = this.formValues
          .create_own_fields || [{}];
      }
    },
    data: () => ({ currentTab: 0, valid: false })
  };
</script>
<style lang="scss">
  .w-50 {
    width: 50%;
  }
  .headerScroll {
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .grey--text {
    color: #848484;
  }
  .characterTab {
    padding: 0px 20px;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 50px;
    cursor: pointer;
    &.active {
      border-bottom: 3px solid #ff2ff2;
    }
  }
</style>
