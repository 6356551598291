import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('h2',{staticClass:"font-weight-medium mb-8"},[_vm._v("Production Details")]),_c(VForm,{ref:"form",attrs:{"submit.preventDefault":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_vm._l((_vm.formConfig),function(formElement){return [_c(VCol,{key:formElement.id,attrs:{"cols":"12","md":"6"}},[_c('CustomInput',_vm._b({attrs:{"dark":"","outlined":""},on:{"input":function($event){return _vm.checkMax(formElement.id, formElement.max || 0, $event)}},model:{value:(_vm.formValues[formElement.id]),callback:function ($$v) {_vm.$set(_vm.formValues, formElement.id, $$v)},expression:"formValues[formElement.id]"}},'CustomInput',formElement,false))],1),(
            formElement.id == 'loading_platform[]' && _vm.isOtherLoadingPlatform
          )?_c(VCol,{key:formElement.id,attrs:{"cols":"12","md":"6"}},[_c('CustomInput',{attrs:{"label":_vm.$t('label.pleaseSpecify'),"placeholder":_vm.$t('label.pleaseSpecify'),"type":"text","rules":[function (v) { return !!v || 'This field is required'; }],"id":"loading_platform_others","name":"loading_platform_others","dark":""},model:{value:(_vm.formValues.loading_platform_others),callback:function ($$v) {_vm.$set(_vm.formValues, "loading_platform_others", $$v)},expression:"formValues.loading_platform_others"}})],1):_vm._e()]})],2)],1),_c('div',{staticClass:"d-flex justify-center btn-holder"},[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.valid},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.valid ? 'Save & Next' : 'Save')+" ")]),(!_vm.$route.query.edit)?_c('div',{staticClass:"underlineBtn mt-7",on:{"click":function($event){return _vm.$emit('doItLater')}}},[_vm._v(" Do it later ")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }