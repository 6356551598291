<template>
  <div>
    <div
      class="headerStep"
      :class="currentStep == steps.length - 1 ? 'active' : ''"
    >
      <div class="container">
        <div class="headerScroll d-flex">
          <div
            v-for="(step, i) in steps"
            :key="i"
            @click="currentStep = i"
            :class="'headerItem ' + (i <= currentStep ? 'active' : '')"
          >
            Step {{ i + 1 }} - {{ step }}
          </div>
        </div>
      </div>
    </div>

    <div class="container wrapper">
      <h1 class="pageHeader my-12 text-uppercase">{{ $t('label.jobPost') }}</h1>

      <JobType
        v-model="formValues.job_type"
        v-if="currentStep == 0"
        @next="currentStep = 1"
      />
      <InfoA
        v-if="currentStep == 1"
        :formValues="formValues"
        @doItLater="processValue(true)"
        @next="processValue()"
      />
      <InfoB
        v-if="currentStep == 2"
        :formValues="formValues"
        :jobCover="jobCover"
        @next="processValue()"
        @doItLater="processValue(true)"
        @coverChange="(e) => (jobCover = e)"
      />
      <Character
        v-if="currentStep == 3"
        :characters="character"
        :projectType="projectType"
        :formValues="formValues"
        @addCharacter="addCharacter"
        @removeCharacter="removeCharacter"
        @doItLater="processValue(true)"
        @next="processValue()"
      />
      <ProductionDetails
        v-if="currentStep == 4"
        :formValues="formValues"
        :projectType="projectType"
        @next="confirmationDialog"
        @doItLater="processValue(true)"
      />
    </div>
  </div>
</template>

<script>
  import JobType from './FormPage/JobType.vue';
  import InfoA from './FormPage/JobInfo1.vue';
  import InfoB from './FormPage/JobInfo2.vue';
  import ProductionDetails from './FormPage/ProductionDetails.vue';
  import Character from './FormPage/Character.vue';
  import { CREATE_JOB, JOB_ORGANIZER } from '@/api';
  import { ROUTE_NAME } from '@/constants';
  import Moment from 'moment';
  import constantsProjectTypes from '@/filters/constants-project-types.filter';
  // import { omit } from 'lodash';
  export default {
    name: 'JobForm',
    components: { JobType, InfoA, InfoB, ProductionDetails, Character },
    computed: {
      projectType() {
        return constantsProjectTypes(this.formValues.project_type_id);
      },
      datasetId() {
        return this.projectType.datasetId;
      }
    },
    watch: {
      datasetId(val) {
        if (val == 4) {
          this.formValues = {
            ...this.formValues,
            create_own_fields: this.formValues.create_own_fields || [{}]
          };
        } else {
          delete this.formValues?.create_own_fields;
        }
      }
    },
    methods: {
      addCharacter() {
        if (
          this.projectType.datasetId == 4 &&
          this.formValues.create_own_fields.length < 3
        ) {
          this.formValues.create_own_fields =
            this.formValues.create_own_fields.concat([{}]);
        } else if (this.projectType.datasetId != 4) {
          this.character = this.character.concat([
            {
              character: '',
              fees: { key: 'SGD', value: '' },
              casting_script_file: null
            }
          ]);
        }
      },
      removeCharacter(index) {
        if (this.character.length > 1 && this.projectType.datasetId != 4) {
          this.character = this.character.filter((i, idx) => idx != index);
        } else if (
          this.projectType.datasetId == 4 &&
          this.formValues.create_own_fields.length > 1
        ) {
          this.formValues.create_own_fields =
            this.formValues.create_own_fields.filter((i, idx) => idx != index);
        }
      },
      async processValue(isDraft = false) {
        this.$startLoading();
        try {
          let jobBody = {
            ...this.formValues,
            fees:
              this.projectType.datasetId == 4
                ? this.formValues?.fees?.value || 0
                : null,
            currency:
              this.formValues?.fees?.key?.value ||
              this.formValues?.fees?.key ||
              'SGD',
            close_date: this.formatDate(
              this?.formValues?.close_date,
              '23:59:59'
            ),
            live_date: this.formatDate(this?.formValues?.live_date),
            video_due: this.formatDate(this?.formValues?.video_due, '23:59:59'),
            production_period_from: this.formatDate(
              this?.formValues?.production_period_from
            ),
            production_period_to: this.formatDate(
              this?.formValues?.production_period_to
            )
          };
          if (this.formValues?.casting_script_file?.id) {
            delete jobBody.casting_script_file;
            jobBody.casting_script_draft_file_name =
              this.formValues?.casting_script_file_name;
          } else if (this.formValues?.casting_script_file?.file) {
            jobBody.casting_script_file =
              this.formValues?.casting_script_file?.file;
          } else {
            jobBody.casting_script_file = null;
          }
          if (this?.jobCover?.id) {
            jobBody.job_cover_draft_file_name =
              this?.formValues?.job_cover_file_name ||
              this?.jobCover?.job_cover_file_name;
          }

          if (this.projectType.datasetId == 4) {
            jobBody = {
              ...jobBody,
              fees: jobBody?.fees?.value || 0,
              currency:
                this.formValues?.fees?.key?.value ||
                this.formValues?.fees?.key ||
                'SGD',
              skills: (jobBody?.skills?.tags || []).join(',')
            };
            this.formValues.create_own_fields.forEach((x, index) => {
              const arr = [
                [`create_own_fields[${index}][name]`, x.name],
                [`create_own_fields[${index}][value]`, x.value]
              ];
              jobBody = {
                ...jobBody,
                ...Object.fromEntries(arr)
              };
            });
            delete jobBody.create_own_fields;
          }

          let job = {};

          let completeProcess = null;

          switch (true) {
            // save draft every next btn (except step 4) click if no job id, and step++
            // save draft and proceed to job organizer draft page if 'do it later' btn clicked
            // diff complete process for diff case
            case (this.currentStep < 4 && !this.formValues.job_id) || isDraft:
              if (this.currentStep != 3) {
                job = this.formValues.casting_job_draft_id
                  ? await CREATE_JOB.updateDraftJob(
                      jobBody,
                      this?.$route?.query?.draft_id ||
                        this.formValues?.casting_job_draft_id
                    )
                  : await CREATE_JOB.postDraftJob(jobBody);

                if (job.data?.casting_job_draft_id)
                  this.formValues.casting_job_draft_id =
                    job.data.casting_job_draft_id;
              }

              completeProcess = () => {
                if (!isDraft) this.currentStep++;
                else {
                  this?.$router?.push({
                    name: ROUTE_NAME.JOB_ORGANIZER,
                    params: { type: 'draft' }
                  });
                }
              };
              break;

            // if step == 4 and no job id, post job, then push to done page
            // check if have draft id, if yes, remove draft
            case this.currentStep == 4 && !isDraft && !this.formValues.job_id:
              job = await CREATE_JOB.postJob(jobBody);
              this.formValues.job_id = job.data.job_id;

              completeProcess = async (jobId) => {
                if (this.formValues?.casting_job_draft_id && jobId) {
                  await JOB_ORGANIZER.removeDraftJob(
                    this.formValues.casting_job_draft_id
                  );
                  delete this.formValues.casting_job_draft_id;
                  this?.$router?.push({
                    name: ROUTE_NAME.CREATE_JOB_DONE,
                    query: {
                      job_id: jobId,
                      edit: this?.$route?.query?.job_id ? 1 : 0
                    }
                  });
                }
              };
              break;

            // if have job id, update job
            // check if step == 4, if yes, push to done page
            case this.formValues.job_id && this.currentStep == 4:
              job = await CREATE_JOB.updateJob(
                jobBody,
                this.formValues.job_id || this?.$route?.query?.job_id
              );

              completeProcess = async (jobId) => {
                this?.$router?.push({
                  name: ROUTE_NAME.CREATE_JOB_DONE,
                  query: {
                    job_id: jobId,
                    edit: this?.$route?.query?.job_id ? 1 : 0
                  }
                });
              };
              break;

            // if have job id, but step < 4, then skip updating job
            case this.formValues.job_id && this.currentStep < 4:
              completeProcess = async () => {
                this.currentStep++;
              };
              break;
          }
          if (this?.jobCover?.file) {
            await CREATE_JOB.uploadCover(
              this.formValues.casting_job_draft_id || this.formValues.job_id,
              {
                file: this.jobCover.file
              },
              !!this.formValues.casting_job_draft_id
            );
          }
          if (this.projectType.datasetId != 4) {
            await Promise.all(
              this?.character?.map(async (character, idx) => {
                let bodyData = {
                  ...character,
                  fees: character?.fees?.value,
                  currency:
                    character?.fees?.key.value || character?.fees?.key || 'SGD',
                  skills: (character?.skills?.tags || []).join(','),
                  reminder: 1
                };

                if (job?.data?.job_id || this.formValues.job_id) {
                  bodyData.casting_job_id =
                    job?.data?.job_id || this.formValues.job_id;
                } else if (
                  job?.data?.casting_job_draft_id ||
                  this.formValues.casting_job_draft_id
                ) {
                  bodyData.casting_job_draft_id =
                    job?.data?.casting_job_draft_id ||
                    this.formValues.casting_job_draft_id;
                }

                if (character?.casting_script_file?.id) {
                  delete bodyData.casting_script_file;
                  bodyData.casting_script_draft_file_name =
                    character?.casting_script_file_name;
                } else if (character?.casting_script_file?.file) {
                  bodyData.casting_script_file =
                    character?.casting_script_file?.file;
                } else {
                  bodyData.casting_script_file = null;
                }
                let char = {};

                if (
                  (job?.data?.job_id || this.formValues.job_id) &&
                  this.currentStep == 4
                ) {
                  char = character?.id
                    ? await CREATE_JOB.updateRole(bodyData, character.id)
                    : await CREATE_JOB.postCharacter(bodyData);
                  this.character[idx].id = char?.data?.id;
                  delete this.character[idx]?.char_draft_id;
                  return;
                } else if (
                  (job?.data?.casting_job_draft_id ||
                    this.formValues.casting_job_draft_id) &&
                  this.currentStep == 3
                ) {
                  char = character?.char_draft_id
                    ? await CREATE_JOB.updateDraftRole(
                        bodyData,
                        character?.char_draft_id
                      )
                    : await CREATE_JOB.postDraftCharacter(bodyData);

                  this.character[idx].char_draft_id = char?.data?.id;
                  return;
                }
              })
            );
          }

          completeProcess(job?.data?.job_id || null);
        } catch (e) {
          this.openErrorAppDialogInfo(e);
        }
        this.$stopLoading();
      },
      formatDate(date, time = '00:00:00') {
        return date ? Moment(date).format(`Y-MM-DD ${time}`) : null;
      },
      confirmationDialog() {
        this.$openModal(
          'messageModal',
          this.$route.query?.job_id
            ? 'Are you sure wanna edit the post?'
            : 'Are you confirm to upload the posting?',
          {
            title: 'Post',
            action: () => {
              this.processValue();
              this.$closeModal();
            }
          }
        );
      },

      async loadEditData(id = null) {
        this.$startLoading();
        try {
          let response = null;
          if (this.$route?.query?.job_id) {
            response = await CREATE_JOB.getSingleJobData(
              this?.$route?.query?.job_id
            );
            this.formValues.job_id = this?.$route?.query?.job_id;
          } else {
            response = await CREATE_JOB.getSingleDraftJobData(
              this?.$route?.query?.draft_id || id
            );
          }
          const job = response.data;
          this.formValues = {
            ...this.formValues,
            currency: job.currency || 'SGD',
            job_type: job.job_type || '',
            project_type_id: job.project_type_id || '',
            'job_country_id[]': job.job_country_id || [],
            'applicant_country_id[]': job.applicant_country_id || [],
            close_date: job.close_date || '',
            video_due: job.video_due || '',
            live_date: job.live_date || '',
            is_paid: job.is_paid || 0,
            is_wfh: job.is_wfh || 0,
            title: job.title || '',
            synopsis: job.synopsis || '',
            production_period_from: job.production_period_from || '',
            production_period_to: job.production_period_to || '',
            shooting_days: job.shooting_days || '',
            hours_per_day: job.hours_per_day || '',
            job_cover_file_name: job.job_cover_file_name || null,
            casting_job_draft_id: job.casting_job_draft_id || null
          };

          switch (this.projectType.datasetId) {
            case 2:
              this.formValues = {
                ...this.formValues,
                loading_period: job.loading_period || null,
                'loading_platform[]': job.loading_platform || [],
                loading_platform_others: job.loading_platform_others || '',
                territory: job.territory || null
              };
              break;
            case 3:
              this.formValues = {
                ...this.formValues,
                objective: job.objective,
                target_audience: job.target_audience,
                casting_script_file: job.casting_script_file,
                casting_script_file_name: job.casting_script_file_name,
                campaign_duration: job.campaign_duration
              };
              break;
            case 4:
              this.formValues = {
                ...this.formValues,
                create_own_fields: job.create_own_fields,
                currency: job.currency,
                fees: {
                  key: {
                    title: job.currency || 'SGD',
                    value: job.currency || 'SGD'
                  },
                  value: job.fees
                },
                gender: job.gender,
                gender_other: job.gender_other,
                height: job.height,
                on_screen_age: job.on_screen_age || '',
                casting_script_file: job.casting_script_file,
                age: job.age,
                skill: {
                  tags: job.skill?.split(' ') || []
                },
                opening_number: job.opening_number
              };
              break;
          }
          const castingRoles =
            job?.casting_roles || job?.casting_role_drafts || [];

          this.character = castingRoles.length
            ? castingRoles.map((c) => {
                const skill = c?.skills ? c.skills.split(' ') : [];
                const languages =
                  c.languages_spoken?.map((l) => Number(l)) || [];
                const races = Array.isArray(c.race)
                  ? c.race
                  : new Array(c.race);

                const val = {
                  ...c,
                  skills: {
                    tags: skill
                  },
                  'location[]': c.location,
                  'languages_spoken[]': languages,
                  'project_role_id[]': c.project_role_id,
                  fees: {
                    key: {
                      title: c.currency || 'SGD',
                      value: c.currency || 'SGD'
                    },
                    value: c.fees
                  },
                  'race[]': races.map((r) => Number(r)) || [],
                  casting_script_file: c.casting_script_file
                    ? {
                        ...c.casting_script_file,
                        fileName: c.casting_script_file?.name || null
                      }
                    : null
                };

                delete val.location;
                delete val.languages_spoken;
                // delete val.currency;
                delete val.project_role_id;
                delete val.race;
                return val;
              })
            : [
                {
                  character: '',
                  fees: { key: 'SGD', value: null },
                  casting_script_file: null
                }
              ];

          this.jobCover = job.job_cover_file
            ? {
                ...job.job_cover_file,
                fileName: job.job_cover_file?.name || null
              }
            : null;
        } catch (e) {
          this.openErrorAppDialogInfo(e);
        }
        this.$stopLoading();
      },
      openErrorAppDialogInfo(e) {
        this.openAppDialogInfo(
          'error',
          e.error_message || 'Request Failed',
          '',
          [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]
        );
      }
    },
    async mounted() {
      if (this?.$route?.query?.job_id || this?.$route?.query?.draft_id) {
        this.loadEditData();
      }
    },
    data: () => ({
      currentStep: 0,
      formValues: {
        job_type: '',
        project_type_id: '',
        'job_country_id[]': [],
        'applicant_country_id[]': [],
        close_date: '',
        video_due: '',
        live_date: '',
        is_paid: 0,
        is_wfh: 0,
        title: '',
        synopsis: '',
        production_period_from: '',
        production_period_to: '',
        shooting_days: '',
        hours_per_day: ''
      },
      character: [{ character: '', fees: { key: 'SGD', value: null } }],
      jobCover: null,
      steps: [
        'Type of invitation',
        'Job Info A',
        'Job Info B',
        'Character Details',
        'Production Details'
      ]
    })
  };
</script>
<style lang="scss" scoped></style>
