<template>
  <div class="w-100">
    <h2 class="font-weight-medium mb-8">Production Details</h2>
    <v-form ref="form" v-model="valid" submit.preventDefault>
      <v-row>
        <template v-for="formElement in formConfig">
          <v-col :key="formElement.id" cols="12" md="6">
            <CustomInput
              v-model="formValues[formElement.id]"
              v-bind="formElement"
              dark
              outlined
              @input="checkMax(formElement.id, formElement.max || 0, $event)"
            />
          </v-col>
          <v-col
            :key="formElement.id"
            cols="12"
            md="6"
            v-if="
              formElement.id == 'loading_platform[]' && isOtherLoadingPlatform
            "
          >
            <CustomInput
              v-model="formValues.loading_platform_others"
              :label="$t('label.pleaseSpecify')"
              :placeholder="$t('label.pleaseSpecify')"
              type="text"
              :rules="[(v) => !!v || 'This field is required']"
              id="loading_platform_others"
              name="loading_platform_others"
              dark
            />
          </v-col>
        </template>
      </v-row>
    </v-form>
    <div class="d-flex justify-center btn-holder">
      <div class="text-center">
        <v-btn class="btn1" :disabled="!valid" @click="submitForm">
          {{ valid ? 'Save & Next' : 'Save' }}
        </v-btn>

        <div
          class="underlineBtn mt-7"
          v-if="!$route.query.edit"
          @click="$emit('doItLater')"
        >
          Do it later
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CustomInput from '@/components/inputs/custom-input.vue';
  import { mapGetters } from 'vuex';
  export default {
    name: 'ProductionDetails',
    props: ['formValues', 'projectType'],
    components: {
      CustomInput
    },
    computed: {
      isFormValid() {
        return this?.$refs?.form?.validate();
      },
      ...mapGetters({
        project_type_options: 'constants/project_type_options',
        countries: 'constants/countries',
        allCountries: 'constants/allCountries'
      }),
      formConfig() {
        const days = (start, end) => {
          if (!(start && end)) return 0;
          let difference = new Date(end).getTime() - new Date(start).getTime();
          let total = Math.ceil(difference / (1000 * 3600 * 24)) + 1; //include the end date itself

          return total;
        };

        const formFields = [
          {
            id: 'production_period_from',
            name: 'production_period_from',
            placeholder: 'Production Start Date',
            rules: [(v) => !!v || 'Production Start Date is required'],
            label: 'Production Start Date',
            type: 'date',
            required: true,
            config: {
              customSelectAfterDate: true,
              dateStart:
                this.formValues.live_date || new Date().toJSON().slice(0, 10)
            }
          },
          {
            id: 'production_period_to',
            name: 'production_period_to',
            placeholder: 'Production End Date',
            rules: [(v) => !!v || 'Production End Date is required'],
            label: 'Production End Date',
            type: 'date',
            required: true,
            config: {
              customSelectAfterDate: true,
              dateStart:
                this.formValues.production_period_from ||
                this.formValues.live_date ||
                new Date().toJSON().slice(0, 10)
            }
          },
          {
            id: 'shooting_days',
            name: 'shooting_days',
            placeholder: 'No. of Shoot Days (Optional)',
            rules: [
              (v) =>
                v <=
                  days(
                    this.formValues.production_period_from,
                    this.formValues.production_period_to
                  ) ||
                `Please input number lesser than ${days(
                  this.formValues.production_period_from,
                  this.formValues.production_period_to
                )}`
            ],
            label: 'No. of Shoot Days (Optional)',
            type: 'number',
            required: false,
            disabled: !(
              this.formValues.production_period_from &&
              this.formValues.production_period_to
            ),
            max: days(
              this.formValues.production_period_from,
              this.formValues.production_period_to
            )
          },
          {
            id: 'hours_per_day',
            name: 'hours_per_day',
            placeholder: 'No. of Hours Per Day (Optional)',
            rules: [(v) => v <= 24 || `Please input number lesser than 24`],
            label: 'No. of Hours Per Day (Optional)',
            type: 'number',
            required: false,
            max: 24
          }
        ];
        if ([2, 4].includes(this.projectType.datasetId)) {
          formFields.push(
            {
              id: 'loading_platform[]',
              name: 'loading_platform[]',
              placeholder: 'Loading Platform (Optional)',
              rules: [],
              label: 'Loading Platform (Optional)',
              type: 'select',
              config: {
                items: this.$store.getters['constants/loadingPlatform'],
                multiple: true,
                clearable: true
              },
              required: false
            },
            {
              id: 'loading_period',
              name: 'loading_period',
              placeholder: 'Loading Period (Optional)',
              rules: [],
              label: 'Loading Period (Optional)',
              type: 'select',
              config: {
                items: this.$store.getters['constants/loadingPeriod'],
                clearable: true
              },
              required: false
            },
            {
              id: 'territory',
              name: 'territory',
              placeholder: 'Territory (Optional)',
              rules: [],
              label: 'Territory (Optional)',
              type: 'select',
              config: {
                items: this.$store.getters['constants/allCountries'],
                multiple: true,
                clearable: true
              },
              required: false
            }
          );
        } else if (this.projectType.datasetId == 3) {
          formFields.push({
            id: 'campaign_duration',
            name: 'campaign_duration',
            placeholder: 'Duration of Campaign (Optional)',
            rules: [],
            label: 'Duration of Campaign (Optional)',
            type: 'select',
            config: {
              items: this.$store.getters['constants/loadingPeriod'],
              clearable: true
            },
            required: false
          });
        }
        return formFields;
      },
      isOtherLoadingPlatform() {
        if (this.formValues['loading_platform[]']) {
          const otherKey = this.$store.getters[
            'constants/loadingPlatform'
          ].find((x) => x.text == 'Others');
          return (
            [2, 4].includes(this.projectType.datasetId) &&
            this.formValues['loading_platform[]'].includes(otherKey.value)
          );
        }
        return false;
      }
    },
    methods: {
      submitForm() {
        if (this.$refs.form.validate()) this.$emit('next');
      },
      checkMax(key, max, e) {
        if (!['shooting_days', 'hours_per_day'].includes(key)) return;
        this.formValues[key] = Number(e) > Number(max) ? max : e;
      }
    },
    mounted() {
      this.$scrollToTop();
    },
    data: () => ({
      valid: false
    })
  };
</script>
<style lang="scss">
  .w-50 {
    width: 50%;
  }

  .grey--text {
    color: #848484;
  }
</style>
