<template>
  <div>
    <JobForm />
  </div>
</template>

<script>
  import JobForm from '@/views/CreateJob/JobForm.vue';
  export default {
    name: 'CreateJob',
    components: {
      JobForm
    },
    data: () => ({})
  };
</script>
