import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('h2',{staticClass:"font-weight-medium mb-8"},[_vm._v("Job Info")]),_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_vm._l((_vm.formConfig),function(formElement){return [_c(VCol,{key:formElement.id,attrs:{"cols":"12","md":"12"}},[_c('CustomInput',_vm._b({attrs:{"dark":"","outlined":""},model:{value:(_vm.formValues[formElement.id]),callback:function ($$v) {_vm.$set(_vm.formValues, formElement.id, $$v)},expression:"formValues[formElement.id]"}},'CustomInput',formElement,false))],1)]})],2),_c('div',{staticClass:"mt-10"},[_c('h4',{staticClass:"font-weight-regular mb-5"},[_vm._v(" Job Cover "),_c('span',{staticClass:"grey--text"},[_vm._v("(Optional)")])]),_c('CustomInput',{attrs:{"name":"jobCover","id":_vm.file,"type":"file","config":{
          accept: 'image/*'
        },"dark":"","outlined":""},on:{"input":function($event){return _vm.$emit('coverChange', _vm.cover)},"clearFile":_vm.removeAsset},model:{value:(_vm.cover),callback:function ($$v) {_vm.cover=$$v},expression:"cover"}})],1)],1),_c('div',{staticClass:"d-flex justify-center btn-holder"},[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"btn1",attrs:{"disabled":!_vm.valid},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.valid ? 'Save & Next' : 'Save')+" ")]),(!_vm.$route.query.edit)?_c('div',{staticClass:"underlineBtn mt-7",on:{"click":function($event){return _vm.$emit('doItLater')}}},[_vm._v(" Do it later ")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }