<template>
  <div class="w-100">
    <h2 class="font-weight-medium mb-8">Job Info</h2>
    <v-form ref="form" v-model="valid">
      <v-row>
        <template v-for="formElement in formConfig">
          <v-col :key="formElement.id" cols="12" md="12">
            <CustomInput
              v-model="formValues[formElement.id]"
              v-bind="formElement"
              dark
              outlined
            />
          </v-col> </template
      ></v-row>
      <div class="mt-10">
        <h4 class="font-weight-regular mb-5">
          Job Cover <span class="grey--text">(Optional)</span>
        </h4>
        <CustomInput
          v-model="cover"
          name="jobCover"
          :id="file"
          type="file"
          :config="{
            accept: 'image/*'
          }"
          @input="$emit('coverChange', cover)"
          dark
          outlined
          @clearFile="removeAsset"
        />
      </div>
    </v-form>
    <div class="d-flex justify-center btn-holder">
      <div class="text-center">
        <v-btn class="btn1" :disabled="!valid" @click="submitForm">
          {{ valid ? 'Save & Next' : 'Save' }}
        </v-btn>

        <div
          class="underlineBtn mt-7"
          v-if="!$route.query.edit"
          @click="$emit('doItLater')"
        >
          Do it later
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CustomInput from '@/components/inputs/custom-input.vue';
  import { mapGetters } from 'vuex';
  import { PROFILE } from '@/api';
  export default {
    name: 'JobInfo2',
    props: ['formValues', 'jobCover'],
    components: {
      CustomInput
    },
    data() {
      return { valid: false, cover: this.jobCover };
    },
    mounted() {
      this.$scrollToTop();
    },
    computed: {
      isFormValid() {
        return this?.$refs?.form?.validate();
      },
      ...mapGetters({
        project_type_options: 'constants/project_type_options',
        countries: 'constants/countries',
        allCountries: 'constants/allCountries'
      }),
      formConfig() {
        return [
          {
            id: 'title',
            name: 'title',
            placeholder: 'Title',
            rules: [(v) => !!v || 'Title is required'],
            label: 'Title',
            type: 'text',

            required: true
          },
          {
            id: 'synopsis',
            name: 'synopsis',
            placeholder: 'Synopsis',
            rules: [
              (v) => v.length || 'Synopsis is required',
              (v) =>
                v.length <= 1000 || 'Synopsis not more than 1000 characters'
            ],
            label: 'Synopsis',
            type: 'textarea',
            multiline: true,
            rows: 6,
            required: true
          }
        ];
      }
    },

    methods: {
      submitForm() {
        if (this.$refs.form.validate()) this.$emit('next');
      },
      async removeAsset(e) {
        if (e.id) {
          this.$startLoading();
          try {
            await PROFILE.removeFile(e.id);
          } catch (e) {
            console.error(e);
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        }
      }
    }
  };
</script>
<style lang="scss">
  .w-50 {
    width: 50%;
  }

  .grey--text {
    color: #848484;
  }
</style>
